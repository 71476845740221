import { useCallback } from "react";
import { useNetwork } from "wagmi";
import { postDataApi } from "../../api/methods";
import { constants } from "../../helpers";
import { errorAlert } from "../../utils";
import { useAppDispatch } from "./redux";
// import { onToggleModal, setRefreshCollectiveDetails } from "../../redux/features/commonSlice";
import { useNavigate } from "react-router-dom";
import { setRefreshUserDetails, walletTransactionRefershAlert } from "../../redux/features/commonSlice";

function useGasCredit({ userId, amount,setAmount, setIsSubmitting, setShow }) {
  const dispatch = useAppDispatch();
  const { chain } = useNetwork();
  const navigate = useNavigate();

  const onGasCredit = useCallback(
    (transactionHash) => {
      try {
        if (!transactionHash) return false;

        // disabled save changes before trigger api call
        setIsSubmitting(true);

        const request = {
          // collectiveId:collectiveId,
          userId: userId,
          amount: amount,
          notify: true,
          callback: (response) => {
            if (response?.statusCode === 200) {
              dispatch(walletTransactionRefershAlert(false));
              dispatch(setRefreshUserDetails(true));
              setTimeout(() => {
                dispatch(setRefreshUserDetails(false));
              }, 1000);
            } else {
              console.log(response, "ERROR");
            }

            // enabled save changes after api success
            setIsSubmitting(false);
            setShow(false)
            setAmount('');
          },
        };
        postDataApi({
          path: constants.API_PATHS.GAS_CREDIT,
          data: request,
        });
      } catch (error) {
        errorAlert('Transaction has been denied');
        setIsSubmitting(false);
        setShow(false);
        setAmount('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userId, amount, chain, navigate, dispatch]
  );

  return { onGasCredit };
}

export default useGasCredit;
