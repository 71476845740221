import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppSelector } from '../common/custom-hooks';
import Layout from '../common/components/layout';
import getRoutes from './routes';
import { useMemo,Suspense, useEffect } from 'react';
import { Bars } from 'react-loader-spinner'
function RoutesWrapper(props) {
  // const {
  //   auth: { isloggedin }
  // } = useAppSelector((state) => state);
  const { auth: authReducer, common: commonReducer } = useAppSelector((state) => state);
  const routes = useMemo(() => getRoutes(authReducer.isloggedin), [authReducer.isloggedin]);

  useEffect(() => {
		if(commonReducer.walletTransactionInprogress){
			const handleBeforeUnload = (e) => {
				e.preventDefault();
				e.returnValue = 'Are you sure you want to leave this page?';
			  };
			
			  window.addEventListener('beforeunload', handleBeforeUnload);
			
			  return () => {
				window.removeEventListener('beforeunload', handleBeforeUnload);
			  };
			}
	  }, [commonReducer]);

  return (
    <Suspense fallback={
      <div className='d-flex justify-content-center' style={{ zIndex: 1050,marginTop:"5rem" }}>
        <Bars
          height="80"
          width="80"
          color="grey"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    }
    >
      <BrowserRouter>
        <Layout {...props} isloggedin={authReducer.isloggedin}>
          <Routes>
            {routes.map((route) => {
              return <Route key={route.name} path={route.path} element={<route.Component {...props}/>} />;
            })}
          </Routes>
          {/* <AllInOneModal
								isOpen={!!modal.openedModalType}
								type={modal.openedModalType}
							/> */}
        </Layout>
      </BrowserRouter>
      </Suspense>
  );
}

export default RoutesWrapper;
